@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
    box-sizing: border-box;
}
body * {
    max-width: 100%;
}